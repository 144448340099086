@import "utils";

.container {
  display: flex;
  gap: rem-calc(24);

  margin: 0;
  padding: rem-calc(0 24);
  position: sticky;
  top: var(--header-height);
  width: 100%;
  z-index: 3;

  overflow: hidden;

  background-color: getColor($c-bg-neutral);
  border-bottom: 1px solid getColor($c-border);

  @include breakpoint(small only) {
    flex-direction: column;
    gap: rem-calc(12);

    min-height: rem-calc(50);
    padding: rem-calc(12 10);
  }
}

.titleContainer {
  display: flex;
  flex-shrink: 0;
  gap: rem-calc(8);
  align-items: center;
  height: rem-calc(72);

  @include text-platform-h1;

  color: getColor($c-text-default);

  @include breakpoint(small only) {
    height: 100%;
  }
}

.title {
  @include breakpoint(small only) {
    @include text-platform-h3;
    position: relative;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    margin-right: rem-calc(32);
  }
}

.headerTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
