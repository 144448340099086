@import "utils";

.modal {
  @include breakpoint(small only) {
    margin: rem-calc(16);
  }
}

.modalTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.modalDescription {
  width: 336px;
  text-align: center;

  color: getColor($c-text-secondary);

  @include breakpoint(small only) {
    width: 100%;
  }
}


