@import 'utils';

$tag-padding: --tag-padding;
$tag-gap: --tag-gap;
$tag-height: --tag-height;
$tag-icon-size: --tag-icon-size;
$tag-text-size: --tag-text-size;
$tag-line-height: --tag-line-height;
$tag-background-color: --tag-background-color;
$tag-border-color: --tag-border-color;
$tag-text-color: --tag-text-color;
$tag-icon-color: --tag-icon-color;

$tag-colors: (
  default: (
    accent: (
      #{$tag-text-color}: $c-text-inverse,
      #{$tag-background-color}: $c-bg-inverse,
      #{$tag-border-color}: $c-border-inverse,
      #{$tag-icon-color}: $c-icon-inverse,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-default,
      #{$tag-background-color}: $c-bg-neutral-secondary,
      #{$tag-border-color}: $c-border,
      #{$tag-icon-color}: $c-icon,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-default,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon,
    ),
  ),
  neutral: (
    accent: (
      #{$tag-text-color}: $c-text-default,
      #{$tag-background-color}: $c-bg-neutral,
      #{$tag-border-color}: $c-border,
      #{$tag-icon-color}: $c-icon,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-secondary,
      #{$tag-background-color}: $c-bg-neutral,
      #{$tag-border-color}: $c-border,
      #{$tag-icon-color}: $c-icon-secondary,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-secondary,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon-secondary,
    ),
  ),
  brand: (
    accent: (
      #{$tag-text-color}: $c-text-inverse,
      #{$tag-background-color}: $c-fill-brand-bold,
      #{$tag-border-color}: $c-border-brand-bold,
      #{$tag-icon-color}: $c-icon-inverse,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-brand,
      #{$tag-background-color}: $c-bg-brand-secondary,
      #{$tag-border-color}: $c-border-brand,
      #{$tag-icon-color}: $c-icon-brand,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-brand,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon-brand,
    ),
  ),
  info: (
    accent: (
      #{$tag-text-color}: $c-text-inverse,
      #{$tag-background-color}: $c-fill-info-bold,
      #{$tag-border-color}: $c-border-info-bold,
      #{$tag-icon-color}: $c-icon-inverse,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-info,
      #{$tag-background-color}: $c-bg-info-secondary,
      #{$tag-border-color}: $c-border-info,
      #{$tag-icon-color}: $c-icon-info,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-info,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon-info,
    ),
  ),
  success: (
    accent: (
      #{$tag-text-color}: $c-text-inverse,
      #{$tag-background-color}: $c-fill-success-bold,
      #{$tag-border-color}: $c-border-success-bold,
      #{$tag-icon-color}: $c-icon-inverse,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-success,
      #{$tag-background-color}: $c-bg-success-secondary,
      #{$tag-border-color}: $c-border-success,
      #{$tag-icon-color}: $c-icon-success,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-success,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon-success,
    ),
  ),
  warning: (
    accent: (
      #{$tag-text-color}: $c-text-default,
      #{$tag-background-color}: $c-fill-warning-bold,
      #{$tag-border-color}: $c-border-warning-bold,
      #{$tag-icon-color}: $c-icon,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-warning,
      #{$tag-background-color}: $c-bg-warning-secondary,
      #{$tag-border-color}: $c-border-warning,
      #{$tag-icon-color}: $c-icon-warning,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-warning,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon-warning,
    ),
  ),
  danger: (
    accent: (
      #{$tag-text-color}: $c-text-inverse,
      #{$tag-background-color}: $c-fill-danger-bold,
      #{$tag-border-color}: $c-border-danger-bold,
      #{$tag-icon-color}: $c-icon-inverse,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-danger,
      #{$tag-background-color}: $c-bg-danger-secondary,
      #{$tag-border-color}: $c-border-danger,
      #{$tag-icon-color}: $c-icon-danger,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-danger,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon-danger,
    ),
  ),
  rose: (
    accent: (
      #{$tag-text-color}: $c-text-inverse,
      #{$tag-background-color}: $c-fill-accent-rose-bold,
      #{$tag-border-color}: $c-border-accent-rose-bold,
      #{$tag-icon-color}: $c-icon-inverse,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-accent-rose,
      #{$tag-background-color}: $c-bg-accent-rose-secondary,
      #{$tag-border-color}: $c-border-accent-rose,
      #{$tag-icon-color}: $c-icon-accent-rose,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-accent-rose,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon-accent-rose,
    ),
  ),
  magenta: (
    accent: (
      #{$tag-text-color}: $c-text-inverse,
      #{$tag-background-color}: $c-fill-accent-magenta-bold,
      #{$tag-border-color}: $c-border-accent-magenta-bold,
      #{$tag-icon-color}: $c-icon-inverse,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-accent-magenta,
      #{$tag-background-color}: $c-bg-accent-magenta-secondary,
      #{$tag-border-color}: $c-border-accent-magenta,
      #{$tag-icon-color}: $c-icon-accent-magenta,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-accent-magenta,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon-accent-magenta,
    ),
  ),
  purple: (
    accent: (
      #{$tag-text-color}: $c-text-inverse,
      #{$tag-background-color}: $c-fill-accent-purple-bold,
      #{$tag-border-color}: $c-border-accent-purple-bold,
      #{$tag-icon-color}: $c-icon-inverse,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-accent-purple,
      #{$tag-background-color}: $c-bg-accent-purple-secondary,
      #{$tag-border-color}: $c-border-accent-purple,
      #{$tag-icon-color}: $c-icon-accent-purple,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-accent-purple,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon-accent-purple,
    ),
  ),
  teal: (
    accent: (
      #{$tag-text-color}: $c-text-inverse,
      #{$tag-background-color}: $c-fill-accent-teal-bold,
      #{$tag-border-color}: $c-border-accent-teal-bold,
      #{$tag-icon-color}: $c-icon-inverse,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-accent-teal,
      #{$tag-background-color}: $c-bg-accent-teal-secondary,
      #{$tag-border-color}: $c-border-accent-teal,
      #{$tag-icon-color}: $c-icon-accent-teal,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-accent-teal,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon-accent-teal,
    ),
  ),
  lime: (
    accent: (
      #{$tag-text-color}: $c-text-inverse,
      #{$tag-background-color}: $c-fill-accent-lime-bold,
      #{$tag-border-color}: $c-border-accent-lime-bold,
      #{$tag-icon-color}: $c-icon-inverse,
    ),
    subtle: (
      #{$tag-text-color}: $c-text-accent-lime,
      #{$tag-background-color}: $c-bg-accent-lime-secondary,
      #{$tag-border-color}: $c-border-accent-lime,
      #{$tag-icon-color}: $c-icon-accent-lime,
    ),
    no-background: (
      #{$tag-text-color}: $c-text-accent-lime,
      #{$tag-background-color}: $c-bg-transparent,
      #{$tag-border-color}: $c-border-transparent,
      #{$tag-icon-color}: $c-icon-accent-lime,
    ),
  ),
);

$tag-sizes: (
  small: (
    #{$tag-height}: rem-calc(24),
    #{$tag-icon-size}: rem-calc(10),
    #{$tag-padding}: rem-calc(5 6 5 6),
    #{$tag-gap}: rem-calc(2),
    #{$tag-text-size}: rem-calc(10),
    #{$tag-line-height}: rem-calc(14),
  ),
  medium: (
    #{$tag-height}: rem-calc(30),
    #{$tag-icon-size}: rem-calc(12),
    #{$tag-padding}: rem-calc(4 8 4 8),
    #{$tag-gap}: rem-calc(2),
    #{$tag-text-size}: rem-calc(12),
    #{$tag-line-height}: rem-calc(20),
  ),
  large: (
    #{$tag-height}: rem-calc(36),
    #{$tag-icon-size}: rem-calc(14),
    #{$tag-padding}: rem-calc(7 10 7 10),
    #{$tag-gap}: rem-calc(2),
    #{$tag-text-size}: rem-calc(14),
    #{$tag-line-height}: rem-calc(22),
  ),
);

.container {
  display: flex;
  align-items: center;
  gap: var($tag-gap);

  width: max-content;
  height: var($tag-height);
  padding: var($tag-padding);

  border-radius: calc(var($tag-height) / 2);
  background-color: var($tag-background-color);
  border: 1px solid var($tag-border-color);

  @each $size, $values in $tag-sizes {
    &--size-#{$size} {
      #{$tag-height}: map-get($values, $tag-height);
      #{$tag-icon-size}: map-get($values, $tag-icon-size);
      #{$tag-padding}: map-get($values, $tag-padding);
      #{$tag-gap}: map-get($values, $tag-gap);
      #{$tag-text-size}: map-get($values, $tag-text-size);
      #{$tag-line-height}: map-get($values, $tag-line-height);
    }
  }

  @each $color, $modes in $tag-colors {
    &--color-#{$color} {
      @each $mode, $values in $modes {
        &-#{$mode} {
          #{$tag-text-color}: getColor(map-get($values, $tag-text-color));
          #{$tag-background-color}: getColor(map-get($values, $tag-background-color));
          #{$tag-border-color}: getColor(map-get($values, $tag-border-color));
          #{$tag-icon-color}: getColor(map-get($values, $tag-icon-color));

          // Disable padding and height for no-background mode
          @if $mode == 'no-background' {
            #{$tag-height}: 'auto';
            #{$tag-padding}: 0;
          }

          // Override colors for accent warning tags in dark mode
          @if $mode == 'accent' and $color == 'warning' {
            @include withTheme($dark-mode-theme) {
              #{$tag-text-color}: getColor($c-text-inverse);
              #{$tag-icon-color}: getColor($c-icon-inverse);
            }

            @include withTheme($knowely-dark-mode-theme) {
              #{$tag-text-color}: getColor($c-text-inverse);
              #{$tag-icon-color}: getColor($c-icon-inverse);
            }
          }
        }
      }
    }
  }

  :global(#{$icon}) {
    @include square(var($tag-icon-size));
  }
}

.text {
  @include text-platform-small;

  margin-inline: var($tag-gap);
  text-wrap: nowrap;
  color: var($tag-text-color);
  font-size: var($tag-text-size);
  line-height: var($tag-line-height);
}

.icon {
  color: var($tag-icon-color);
}
