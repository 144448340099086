@import "utils";

.icon {
	color: getColor($c-bg-neutral-quaternary);
}

.imageLoaded {
	animation: scale-in-center $duration $ease-in;
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
