@import 'utils';

.wrapper {
  --achievements-columns: var(--achievements-columns-large);
  --achievement-items-gap: var(--achievement-items-large-gap);
  --achievements-items-width: var(--achievements-columns) * var(--achievement-item-width);
  --achievements-gaps-width: (var(--achievements-columns) - 1) * var(--achievement-items-gap);
  --wrapper-padding: #{rem-calc(40)};
  --categories-gap: #{rem-calc(56)};

  display: flex;
  flex-wrap: wrap;
  gap: var(--categories-gap);

  width: calc(var(--achievements-items-width) + var(--achievements-gaps-width) + 2 * var(--wrapper-padding));

  padding: var(--wrapper-padding);
  margin-inline: auto;
  margin-block: rem-calc(40);

  background-color: getColor($c-bg-neutral);
  border-radius: rem-calc(16);

  @include breakpoint(large down) {
    --achievements-columns: var(--achievements-columns-medium);
  }

  @include breakpoint(small down) {
    --achievements-columns: var(--achievements-columns-small);
    --achievement-items-gap: var(--achievement-items-small-gap);
    --wrapper-padding: #{rem-calc(24)};

    margin-block: rem-calc(16);
  }
}

.fullWidth {
  width: 100%;
}

.halfWidth {
  width: calc((100% - var(--categories-gap)) / 2);

  @include breakpoint(large down) {
    width: 100%;
  }
}
