@import "utils";

.blockedIconWrapper {
  @include breakpoint(medium) {
    margin-left: auto;
  }
}

.blockedIcon {
  color: getColor($c-icon-secondary);
}

.blockedIconToolTip {
  z-index: 2;
}
