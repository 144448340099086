@import 'utils';

$tooltip-arrow-color: getColor($c-bg-inverse);

.parent {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.increasedHoverArea::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  width: calc(100% + 8px);
  height: calc(100% + 8px);
}

.tooltipWrapper {
  position: absolute;

  z-index: 6;
}

.leftSideMode {
  &::before {
    right: rem-calc(16);
  }
}

.rightSideMode {
  &::before {
    left: rem-calc(16);
  }
}

.leftSideMode {
  &::before {
    right: rem-calc(16);
  }
}

.rightSideMode {
  &::before {
    left: rem-calc(16);
  }
}

.leftAsideMode {
  &::before {
    right: auto;
    left: 100%;
    border-color: transparent transparent transparent $tooltip-arrow-color;
  }
}

.rightAsideMode {
  &::before {
    left: auto;
    right: 100%;
    border-color: transparent $tooltip-arrow-color transparent transparent;
  }
}

.centerMode {
  &::before {
    left: 50%;
    transform: translateX(-50%);
  }
}

.topMode {
  &::before {
    top: 100%;
    border-color: $tooltip-arrow-color transparent transparent transparent;
  }
}

.bottomMode {
  &::before {
    top: auto;
    bottom: 100%;
    border-color: transparent transparent $tooltip-arrow-color transparent;
  }
}

.topAsideMode {
  &::before {
    bottom: rem-calc(8);
  }
}

.bottomAsideMode {
  &::before {
    top: rem-calc(8);
  }
}
