@import "utils";

.block {
  position: relative;

  @extend %flex-align-centered;
  flex-direction: column;

  text-align: center;
}

.name {
  margin-bottom: rem-calc(4);
  color: getColor($c-text-default);
}

.name.locked {
  color: getColor($c-text-tertiary);
}

.withMargin {
  margin-top: rem-calc(12);
}

.category {
  color: getColor($c-text-tertiary);
}

.category.locked {
  color: getColor($c-text-quaternary);
}

.textTruncate {
  @include elipsis;
}
