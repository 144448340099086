@import "utils";

.tooltipBase {
  @include text-platform-small;
  display: block;
  transition: opacity $duration $easing;

  position: absolute;
  padding: rem-calc(4 12);
  max-width: 150px;
  border-radius: rem-calc(8);
  background-color: getColor($c-bg-inverse);
  color: getColor($c-text-inverse);
  z-index: 2;

  &.renderedInPortal {
    position: static;
  }
}

.tooltipPointer {
  &::before {
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;
  }
}

.tooltip {
  @extend .tooltipBase;

  pointer-events: none;
  opacity: 0;

  @extend .tooltipPointer;
}

.centerMode {
  left: 50%;
  transform: translateX(-50%);

  &::before {
    left: 50%;
    transform: translateX(-50%);
  }
}

.leftSideMode {
  right: calc(50% - 20px);

  &::before {
    right: rem-calc(16);
  }
}

.rightSideMode {
  left: calc(50% - 20px);

  &::before {
    left: rem-calc(16);
  }
}

.minWidthMode {
  width: min-content;
}

.maxWidthMode {
  width: max-content;
}

.fullWidthMode {
  width: 100%;
}

.notDisplayed {
  display: none;
}

.topMode {
  bottom: calc(100% + #{rem-calc(16)});

  &::before {
    top: 100%;
    border-color: getColor($c-bg-inverse) transparent transparent transparent;
  }
}

.bottomMode {
  bottom: auto;
  top: calc(100% + #{rem-calc(16)});

  &::before {
    top: auto;
    bottom: 100%;
    border-color: transparent transparent getColor($c-bg-inverse) transparent;
  }
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  &:hover .tooltip {
    opacity: 1;
  }
}

.visible {
  white-space: break-spaces;
  opacity: 1;
  pointer-events: all;
}
